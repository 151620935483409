<template>
  <div>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
        <a-card>
          <template slot="title">
            <strong>Batal Pencairan</strong>
            <a-button class="btn btn-outline-danger ml-4" @click="handleSubmit"
              >Pembatalan Pencairan</a-button
            >
          </template>
          <div class="row pl-3 pr-3 pt-3 pb-3">
            <!-- <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3">
              <label></label>
            </div> -->

            <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2">
              <label class="mt-1">Rekening</label>
            </div>
            <div class="col-lg-9 col-md-9 col-sm-9">
              <cleave
                :readOnly="false"
                :options="{ delimiter: '.', blocks: [2, 2, 6] }"
                class="ant-input"
                :raw="false"
                style="width: 150px;"
                v-model.lazy="norekening"
                placeholder="No Rekening"
                v-on:keydown.enter.native="checkRekening1"
              />
              <!-- <a-input
                  placeholder=""
                  v-model="maskInput.rekcabang"
                  style="width: 60px;"
                  :readOnly="false"
                ></a-input>
                <a-input
                  class="ml-2"
                  placeholder=""
                  v-model="maskInput.rerekening"
                  style="width: 60px;"
                  :readOnly="false"
                ></a-input>
                <a-input
                  class="ml-2"
                  placeholder=""
                  v-model="maskInput.rerekening1"
                  style="width: 200px;"
                  :readOnly="false"
                  @keydown.enter="rekeningcheck"
                ></a-input> -->
              <a-button class="ml-2" @click="checkRekening1">
                <a-icon type="search"></a-icon>
              </a-button>
            </div>
            <div class="col-sm-2 col-md-2 col-lg-2 mt-1">
              <label class="mt-1">Nama</label>
            </div>
            <div class="col-lg-9 col-md-9 col-sm-9 mt-1">
              <a-input
                style="width: 50%;"
                placeholder="Nama"
                v-model="maskInput.nama"
                :readOnly="true"
              ></a-input>
            </div>
            <div class="col-sm-2 col-md-2 col-lg-2 mt-1">
              <label class="mt-1">Alamat</label>
            </div>
            <div class="col-lg-9 col-md-9 col-sm-9 mt-1">
              <a-input
                style="width: 50%;"
                placeholder="Alamat"
                v-model="maskInput.alamat"
                :readOnly="true"
              ></a-input>
            </div>
            <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2 mt-2">
              <label class="mt-1">Rekening Tabungan</label>
            </div>
            <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 mt-2">
              <a-input
                placeholder="Rekening Tabungan"
                v-model="maskInput.rekeningtabungan"
                style="width: 80%;"
                :readOnly="true"
              ></a-input>
            </div>
            <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2 mt-2">
              <label class="mt-1">Nama Tabungan</label>
            </div>
            <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 mt-2">
              <a-input
                placeholder="Nama Tabungan"
                v-model="maskInput.nama_tabungan"
                style="width: 80%;"
                :readOnly="true"
              ></a-input>
            </div>
            <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2 mt-2">
              <label class="mt-1">Jangka Waktu</label>
            </div>
            <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 mt-2">
              <a-input
                placeholder="0"
                style="width: 70px; text-align: right;"
                v-model="maskInput.jw"
                :readOnly="true"
              ></a-input>
              Bulan
            </div>
            <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2 mt-2">
              <label class="mt-1">Suku Bunga</label>
            </div>
            <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 mt-2">
              <a-input
                placeholder="Suku Bunga"
                v-model="maskInput.sukubunga"
                style="width: 80%;"
                :readOnly="true"
              ></a-input>
            </div>
            <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2 mt-2">
              <label class="mt-1">Plafond</label>
            </div>
            <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 mt-2">
              <a-input
                placeholder="Plafond"
                v-model="maskInput.plafond"
                style="width: 80%;"
                :readOnly="true"
              ></a-input>
            </div>
            <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2 mt-2">
              <label class="mt-1">Biaya Administrasi</label>
            </div>
            <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 mt-2">
              <a-input
                placeholder="Biaya Administrasi"
                v-model="maskInput.biaya_administrasi"
                style="width: 80%;"
                :readOnly="true"
              ></a-input>
            </div>
            <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2 mt-2">
              <label class="mt-1">Biaya Materai</label>
            </div>
            <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 mt-2">
              <a-input
                placeholder="Biaya Materai"
                v-model="maskInput.biaya_materai"
                style="width: 80%;"
                :readOnly="true"
              ></a-input>
            </div>
            <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2 mt-2">
              <label class="mt-1">Biaya Notaris</label>
            </div>
            <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 mt-2">
              <a-input
                placeholder="Biaya Notaris"
                v-model="maskInput.biaya_notaris"
                style="width: 80%;"
                :readOnly="true"
              ></a-input>
            </div>
            <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2 mt-2">
              <label class="mt-1">Biaya Asuransi</label>
            </div>
            <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 mt-2">
              <a-input
                placeholder="Biaya Asuransi"
                v-model="maskInput.biaya_asuransi"
                style="width: 80%;"
                :readOnly="true"
              ></a-input>
            </div>
          </div>
        </a-card>
      </div>
    </div>
    <a-modal v-model="formvisible" :width="700" title="Pencairan Kredit">
      <template slot="footer">
        <a-button class="btn btn-secondary" @click="formvisible = !formvisible"
          >Cancel</a-button
        >
        <a-button class="btn btn-primary" @click="handleSubmit"
          >Submit</a-button
        >
      </template>
      <div class="row">
        <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3">
          <label class="mt-1">Rekening</label>
        </div>
        <div class="col-lg-9 col-md-9 col-sm-9">
          <a-input
            style="width: 50%;"
            placeholder="Rekening"
            v-model="maskInput.rekening"
            :readOnly="true"
          ></a-input>
        </div>
        <div class="col-sm-3 col-md-3 col-lg-3 mt-1">
          <label class="mt-1">Nama</label>
        </div>
        <div class="col-lg-9 col-md-9 col-sm-9 mt-1">
          <a-input
            style="width: 50%;"
            placeholder="Nama"
            v-model="maskInput.nama"
            :readOnly="true"
          ></a-input>
        </div>
        <div class="col-sm-3 col-md-3 col-lg-3 mt-1">
          <label class="mt-1">Alamat</label>
        </div>
        <div class="col-lg-9 col-md-9 col-sm-9 mt-1">
          <a-input
            style="width: 50%;"
            placeholder="Alamat"
            v-model="maskInput.alamat"
            :readOnly="true"
          ></a-input>
        </div>
        <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
          <label class="mt-1">Rekening Tabungan</label>
        </div>
        <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
          <a-input
            placeholder="Rekening Tabungan"
            v-model="maskInput.rekeningtabungan"
            style="width: 80%;"
            :readOnly="true"
          ></a-input>
        </div>
        <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
          <label class="mt-1">Nama Tabungan</label>
        </div>
        <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
          <a-input
            placeholder="Nama Tabungan"
            v-model="maskInput.nama_tabungan"
            style="width: 80%;"
            :readOnly="true"
          ></a-input>
        </div>
        <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
          <label class="mt-1">Jangka Waktu</label>
        </div>
        <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
          <a-input
            placeholder="Jangka Waktu"
            style="width: 70px; text-align: right;"
            v-model="maskInput.jw"
            :readOnly="true"
          ></a-input>
          Bulan
        </div>
        <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
          <label class="mt-1">Suku Bunga</label>
        </div>
        <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
          <a-input
            placeholder="Suku Bunga"
            v-model="maskInput.sukubunga"
            style="width: 80%;"
            :readOnly="true"
          ></a-input>
        </div>
        <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
          <label class="mt-1">Plafond</label>
        </div>
        <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
          <a-input
            placeholder="Plafond"
            v-model="maskInput.plafond"
            style="width: 80%;"
            :readOnly="true"
          ></a-input>
        </div>
        <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
          <label class="mt-1">Biaya Administrasi</label>
        </div>
        <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
          <a-input
            placeholder="Biaya Administrasi"
            v-model="maskInput.biaya_administrasi"
            style="width: 80%;"
            :readOnly="true"
          ></a-input>
        </div>
        <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
          <label class="mt-1">Biaya Materai</label>
        </div>
        <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
          <a-input
            placeholder="Biaya Materai"
            v-model="maskInput.biaya_materai"
            style="width: 80%;"
            :readOnly="true"
          ></a-input>
        </div>
        <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
          <label class="mt-1">Biaya Notaris</label>
        </div>
        <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
          <a-input
            placeholder="Biaya Notaris"
            v-model="maskInput.biaya_notaris"
            style="width: 80%;"
            :readOnly="true"
          ></a-input>
        </div>
        <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
          <label class="mt-1">Biaya Asuransi</label>
        </div>
        <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
          <a-input
            placeholder="Biaya Asuransi"
            v-model="maskInput.biaya_asuransi"
            style="width: 80%;"
            :readOnly="true"
          ></a-input>
        </div>
        <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
          <label class="mt-1">Cara Pencairan</label>
        </div>
        <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
          <a-select
            class=""
            style="width: 200px;"
            v-model="maskInput.cara_pencairan"
          >
            <a-select-option
              v-for="(data, index) in allMaster.carapencairan"
              :key="index"
              :value="data.id"
              >{{ data.keterangan }}</a-select-option
            >
          </a-select>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import moment from 'moment'
import * as lou from '@/services/data/lou'
import Cleave from 'vue-cleave-component'
import { mapState } from 'vuex'
import { Modal } from 'ant-design-vue'

export default {
  computed: mapState(['settings', 'user']),
  components: {
    cleave: Cleave,
  },
  watch: {
    // 'settings.selectedRekening'() {
    //   this.checkRekening()
    // },
  },
  data() {
    return {
      norekening: '',
      searchText: '',
      searchInput: null,
      searchedColumn: '',
      input: { rekening: '' },
      maskInput: {
        id: 0,
        faktur: '',
        cabang: '01',
        rekcabang: '01',
        reregister: '',
        rerekening: '',
        rerekening1: '',
        namanasabah: '',
        alamatnasabah: '',
        telepon: '',
        pekerjaan: '',
        noregister: '',
        alamat: '',
        cara_pencairan: '',
        jw: '',
        nama: '',
        nama_tabungan: '',
        plafond: '',
        biaya_administrasi: '',
        biaya_asuransi: '',
        biaya_materai: '',
        biaya_notaris: '',
        rekening: '',
        rekeningtabungan: '',
        sudah_cair: '',
        sukubunga: '',
      },
      allMaster: {
        carapencairan: [],
      },

      // Modal Property
      formvisible: false,
      carinasabahtitle: 'Cari',
      inputModal: {
        nama: '',
        alamat: '',
        cabang: '',
      },
    }
  },
  created() {
    // this.getAllData()
  },
  methods: {
    moment,

    async checkRekening1() {
      if (this.norekening.length === 12) {
        // console.log('this.norekening', this.norekening)
        var res = await lou.customUrlGet2(
          'batalpencairan_kredit?rekening=' + this.norekening.toString(),
        )
        if (res) {
          if (res.data !== null) {
            this.maskInput.alamat = res.data[0].alamat
            this.maskInput.biaya_administrasi = res.data[0].biaya_administrasi
            this.maskInput.biaya_asuransi = res.data[0].biaya_asuransi
            this.maskInput.biaya_materai = res.data[0].biaya_materai
            this.maskInput.biaya_notaris = res.data[0].biaya_notaris
            this.maskInput.cara_pencairan = res.data[0].cara_pencairan
            this.maskInput.id = res.data[0].id
            this.maskInput.jw = res.data[0].jw
            this.maskInput.nama = res.data[0].nama
            this.maskInput.nama_tabungan = res.data[0].nama_tabungan
            this.maskInput.plafond = res.data[0].plafond
            this.maskInput.rekening = res.data[0].rekening
            this.maskInput.rekeningtabungan = res.data[0].rekeningtabungan
            this.maskInput.sudah_cair = res.data[0].sudah_cair
            this.maskInput.sukubunga = res.data[0].sukubunga
          } else {
            lou.shownotif(
              'Tidak di temukan',
              'Tidak ada data dengan rekening tersebut!',
              'error',
            )
          }
        } else {
          // var res = await lou.customUrlGet2(
          //   'deposito_mutasi/cari?Rekening=' + this.norekening,
          // )
          // if (res) {
          //   if (res.data !== null) {
          //     this.selectedName = { key: 0, label: res.data.nama }
          //     this.input.deposito_id = res.data.id
          //     this.input.sukubunga = res.data.sukubunga
          //     this.maskInput = res.data
          //     this.maskInput.namanasabah = res.data.nama
          //     this.maskInput.alamatnasabah = res.data.alamat
          //     this.maskInput.golongan_deposito = res.data.nama_golongandeposito
          //     this.maskInput.rekening_tabungan = res.data.rekening_tabungan
          //     this.maskInput.tgl_penempatan = res.data.tgl
          //     this.maskInput.saldo_tabungan = lou.curency(res.data.saldo_tabungan)
          //     this.maskInput.tgl_jatuh_tempo = res.data.jthtmp
          //     this.maskInput.no_bilyet_deposito = res.data.nobilyet
          //   }
        }
      }
    },
    async showModal() {
      this.carinasabahvisible = true
      var res = await lou.customUrlGet2('pencairan_kredit')
      if (res) {
        // this.table.tablebawah.datatable = res.data
      }
    },
    handleSubmit() {
      if (this.maskInput.id !== 0) {
        Modal.confirm({
          title: 'Apakah anda yakin?',
          content: 'Data pencairan akan dibatalkan!',
          okText: 'Yes',
          okType: 'danger',
          cancelText: 'No',
          onOk: async () => {
            var res = await lou.customUrlPost2(
              'batalpencairan_kredit/' + this.maskInput.id,
              {},
            )
            if (res) {
              this.resetForm()
            }
          },
          onCancel: () => {
          // console.log('Cancel')
            return false
          },
        })
      } else {
        lou.shownotif('Data belum atau tidak ada!')
      }
    },
    async checkRekening() {
      this.input.rekening = this.settings.selectedRekening
      var text = this.settings.selectedRekening
      this.maskInput.rekcabang = text.slice(0, text.indexOf('.'))
      this.maskInput.rerekening = text.slice(
        text.indexOf('.') + 1,
        text.indexOf('.') + 3,
      )
      var ntext = text.replace('.', '')
      // console.log('ntext', ntext.slice(ntext.indexOf('.') + 1, ntext.length))
      this.maskInput.rerekening1 = ntext.slice(
        ntext.indexOf('.') + 1,
        ntext.length,
      )
      // this.rekeningcheck()
    },
    async rekeningcheck() {
      var res = await lou.customUrlGet2(
        'batalpencairan_kredit?rekening=' +
          this.maskInput.rekcabang +
          '.' +
          this.maskInput.rerekening +
          '.' +
          this.maskInput.rerekening1,
      )
      if (res) {
        if (res.data !== null) {
          lou.shownotif('Ditemukan!', 'Rekening ditemukan.')
          // this.maskInput = this.$g.clone(res.data)
          this.maskInput.alamat = res.data[0].alamat
          this.maskInput.biaya_administrasi = res.data[0].biaya_administrasi
          this.maskInput.biaya_asuransi = res.data[0].biaya_asuransi
          this.maskInput.biaya_materai = res.data[0].biaya_materai
          this.maskInput.biaya_notaris = res.data[0].biaya_notaris
          this.maskInput.cara_pencairan = res.data[0].cara_pencairan
          this.maskInput.id = res.data[0].id
          this.maskInput.jw = res.data[0].jw
          this.maskInput.nama = res.data[0].nama
          this.maskInput.nama_tabungan = res.data[0].nama_tabungan
          this.maskInput.plafond = res.data[0].plafond
          this.maskInput.rekening = res.data[0].rekening
          this.maskInput.rekeningtabungan = res.data[0].rekeningtabungan
          this.maskInput.sudah_cair = res.data[0].sudah_cair
          this.maskInput.sukubunga = res.data[0].sukubunga
          // console.log('this.maskInput', this.maskInput)
        } else {
          lou.shownotif(
            'Tidak ditemukan!',
            'Rekening tidak ditemukan.',
            'error',
          )
        }
      }
    },
    async getAllData() {
      var indexeddb = await lou.getDataFromDb()
      // console.log('indexeddb', indexeddb)
      // console.log('this.settings.whatapp', this.settings.whatapp)
      var allmaster = {}
      var masterIndex = indexeddb.findIndex(
        (x) =>
          x.mykey ===
          'allmaster' +
            this.settings.whatapp.appName.toLowerCase() +
            this.settings.whatapp.id,
      )
      // console.log('masterIndex', indexeddb[masterIndex])
      if (masterIndex < 0) {
        var res1 = await lou.customUrlGet2('master', false, true)
        var master = {
          mykey:
            'allmaster' +
            this.settings.whatapp.appName.toLowerCase() +
            this.settings.whatapp.id,
          data: JSON.stringify(res1.data),
        }
        await lou.addDataToDb(master)
        var nindexeddb = await lou.getDataFromDb()
        var targetIndex = nindexeddb.findIndex(
          (x) =>
            x.mykey ===
            'allmaster' +
              this.settings.whatapp.appName.toLowerCase() +
              this.settings.whatapp.id,
        )
        // console.log('nindexeddb', nindexeddb[targetIndex])
        allmaster = JSON.parse(nindexeddb[targetIndex].data)
      } else {
        allmaster = JSON.parse(indexeddb[masterIndex].data)
      }
      var cabanglist = []
      for (let u = 0; u < this.user.cabangList.length; u++) {
        const element = this.user.cabangList[u]
        // console.log('element', element)
        cabanglist.push({ kode: element.kode, keterangan: element.kode })
      }
      // console.log('indexeddb', indexeddb)
      this.allMaster = allmaster
      // console.log('this.allMaster', this.allMaster)
      var res = await lou.customUrlGet2('pencairan_kredit')
      if (res) {
        // this.table.tablebawah.datatable = res.data
      }
    },
    resetForm() {
      this.maskInput = {
        id: 0,
        faktur: '',
        cabang: '01',
        rekcabang: '01',
        reregister: '',
        rerekening: '',
        rerekening1: '',
        namanasabah: '',
        alamatnasabah: '',
        telepon: '',
        pekerjaan: '',
        noregister: '',
        alamat: '',
        cara_pencairan: '',
        jw: '',
        nama: '',
        nama_tabungan: '',
        plafond: '',
        biaya_administrasi: '',
        biaya_asuransi: '',
        biaya_materai: '',
        biaya_notaris: '',
        rekening: '',
        rekeningtabungan: '',
        sudah_cair: '',
        sukubunga: '',
      }
    },

    // Modal function
    clearRekening() {
      this.settings.selectedRekening = ''
    },
    async findData() {
      var imbuhan = ''
      imbuhan +=
        this.inputModal.nama !== '' ? '?Nama=' + this.inputModal.nama : ''
      imbuhan +=
        this.inputModal.alamat !== ''
          ? this.inputModal.nama !== ''
            ? '&Alamat=' + this.inputModal.alamat
            : '?Alamat=' + this.inputModal.alamat
          : ''
      imbuhan +=
        this.inputModal.cabang !== ''
          ? '&cabang=' + this.inputModal.cabang
          : ''
      var res = await lou.customUrlGet2('carinasabah' + imbuhan)
      if (res) {
        this.table.tableatas.datatable = res.data
        // for (let i = 0; i < 20; i++) {
        //   const element = res.data[0]
        //   this.table.tableatas.datatable.push(element)
        // }
      }
    },
    async nasabahDetail(record) {
      this.loading = true
      var res = await lou.customUrlGet2('carinasabah/detail/' + record.kode)
      if (res) {
        this.maskInput.cabang = record.kode.slice(0, record.kode.indexOf('.'))
        this.maskInput.reregister = record.kode.slice(
          record.kode.indexOf('.') + 1,
          record.kode.length,
        )
        // this.registeridchanged()
        // this.table.tablebawah.datatable = res.data
        this.loading = false
      }
    },
    customRow(record, index) {
      return {
        on: {
          click: (event) => {
            // console.log('record', record)
            // console.log('index', index)
            // console.log('event', event)
            this.settings.selectedKodeRegister = record.kode
            this.nasabahDetail(record)
          },
          contextmenu: (event) => {
            event.preventDefault()
          },
        },
      }
    },
    customRowB(record, index) {
      return {
        on: {
          click: (event) => {
            // console.log('record', record)

            this.input.rekening = record.rekening
            var text = record.rekening
            this.maskInput.rekcabang = text.slice(0, text.indexOf('.'))
            this.maskInput.rerekening = text.slice(
              text.indexOf('.') + 1,
              text.indexOf('.') + 3,
            )
            var ntext = text.replace('.', '')
            // console.log('ntext', ntext.slice(ntext.indexOf('.') + 1, ntext.length))
            this.maskInput.rerekening1 = ntext.slice(
              ntext.indexOf('.') + 1,
              ntext.length,
            )
            this.maskInput = this.$g.clone(record)
            this.maskInput.plafond = this.formatCurrency(record.plafond)
            this.maskInput.biaya_administrasi = this.formatCurrency(
              record.biaya_administrasi,
            )
            this.maskInput.biaya_asuransi = this.formatCurrency(
              record.biaya_asuransi,
            )
            this.maskInput.biaya_materai = this.formatCurrency(
              record.biaya_materai,
            )
            this.maskInput.biaya_notaris = this.formatCurrency(
              record.biaya_notaris,
            )
            // console.log('this.maskInput', this.maskInput)
            this.formvisible = true
            // this.settings.selectedRekening = record.rekening
            // console.log('index', index)
            // console.log('event', event)
            // console.log('this.settings.selectedRekening', this.settings.selectedRekening)
          },
          contextmenu: (event) => {
            event.preventDefault()
          },
        },
      }
    },
    formatCurrency(v) {
      return lou.curency(v)
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      )
    },
    dateFormatter(params) {
      var ret =
        params.value === null
          ? ''
          : moment(params.value, 'YYYY-MM-DD').format('DD-MM-YYYY')
      // console.log('dateFormatter', ret)
      return ret !== 'Invalid date' ? ret : params.value
    },
    currencyFormatter(params) {
      var ret =
        params.value === null
          ? 'NaN'
          : lou.curency(parseFloat(params.value).toFixed(0))
      return ret === 'NaN' ? '' : ret
    },
  },
}
</script>

<style lang="scss" scoped></style>
